<template>
  <div class="topic">
    <container :gutter="0" :sideWidth="0">
      <div class="topic-list-wrap">
        <el-row :gutter="20">
          <el-col :span="8" v-for="i in 24" :key="i">
            <div class="topic-box">
              <div class="poster-title">
                <img
                  style="width: 100%"
                  src="https://dummyimage.com/335x188/1bd1a5"
                  alt=""
                  srcset=""
                />
                <div class="title">
                  <div class="title-text">
                    八月大事件回顾丨自动驾驶≠夺命驾驶，营销过头的后果谁来买单？；幼儿教育，成人教育，教培机构两手抓两手空？
                  </div>
                </div>
              </div>
              <div class="desc">
                <div class="desc-text">
                  一转眼，蒂姆·库克已经接棒史蒂夫·乔布斯管理苹果10年了，
                  以创新精神闻名于世的乔布斯对于苹果的塑造充满革新意义，
                  如今，iPhone13转向强调性价比，让一向毁誉参半的库克风评走向两极，
                  库克经营苹果的理念与乔布斯究竟有何不同？如今61岁的库克考虑退休，
                  下一任接棒者又会是谁？
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "./Container.vue";
export default {
  components: {
    Container,
  },
};
</script>

<style lang="scss" scoped>
.topic {
  padding: 30px 0;

  .topic-box {
    margin-bottom: 30px;

    .poster-title {
      position: relative;
      height: 185px;
      overflow: hidden;

      .title {
        height: 100px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        background-image: linear-gradient(
          -180deg,
          rgba(30, 30, 30, 0),
          rgba(0, 0, 0, 0.78) 98%
        );

        .title-text {
          font-size: 18px;
          // font-weight: bold;
          color: #fff;

          line-height: 24px;
          max-height: 48px;

          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }

    .desc {
      padding: 10px;
      background: #edf1f4;

      .desc-text {
        color: #666;
        text-align: justify;
        font-size: 12px;
        line-height: 18px;
        height: 54px;

        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }
}
</style>